import Vue from "vue";
import Vuex from "vuex";

import htmlClass from "./htmlclass.module";
import config from "./config.module";

import policyAlert from './policyalert.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    htmlClass,
    config,
    policyAlert
  },
  state: {
    wizardTitle: null,
    wizardSubtitle: null,
    transactionStatus: null,
    transactionSubStatus: null,
    transactionBoxFolderLink: null,
    crmRecordLink: null,
    nextBatchTransaction: null,
    claimLodged: false,
    claimFormViewLink: null,
    claimFormInternalLink: null,
    insurerNotified: false,
    quoteBeingUpdated: null,
    appData: null
  },
  mutations: {
    setWizardTitle(state, title) {
      state.wizardTitle = title;
    },
    setWizardSubtitle(state, subtitle) {
      state.wizardSubtitle = subtitle;
    },
    setTransactionStatus(state, transactionStatus) {
      state.transactionStatus = transactionStatus;
    },
    setTransactionSubStatus(state, transactionSubStatus) {
      state.transactionSubStatus = transactionSubStatus;
    },
    setTransactionBoxFolderLink(state, txnBoxFolderLink) {
      state.transactionBoxFolderLink = txnBoxFolderLink;
    },
    setCrmRecordLink(state, crmRecordLink) {
      state.crmRecordLink = crmRecordLink;
    },
    setNextBatchTransaction(state, nextBatchTransaction) {
      state.nextBatchTransaction = nextBatchTransaction;
    },
    setClaimLodged(state, status) {
      state.claimLodged = status;
    },
    setClaimFormInternalLink(state, claimFormInternalLink) {
      state.claimFormInternalLink = claimFormInternalLink;
    },
    setClaimFormViewLink(state, link) {
      state.claimFormViewLink = link;
    },
    setInsurerNotified(state, value) {
      state.insurerNotified = value;
    },
    SET_QUOTE_BEING_UPDATED(state, quote) {
      state.quoteBeingUpdated = quote
    },
    UPDATE_QUOTE(state, updatedQuote) {
      state.quoteBeingUpdated = { ...state.quoteBeingUpdated, ...updatedQuote }
    },
    SET_APP_DATA(state, appData) {
      state.appData = appData
    }
  },

  actions: {
    setQuoteBeingUpdated({ commit }, quote) {
      commit('SET_QUOTE_BEING_UPDATED', _.cloneDeep(quote))
    },
    updateQuote({ commit }, updatedQuote) {
      commit('UPDATE_QUOTE', updatedQuote)
    },
    setAppData({ commit }, appData) {
      commit('SET_APP_DATA', appData)
    }
  },

  getters: {
    isClaimLodged: state => state.claimLodged,
    isInsurerNotified: state => state.insurerNotified,
    claimFormLink: (state) => {
      return state.insurerNotified || !state.claimFormInternalLink
        ? state.claimFormViewLink
        : state.claimFormInternalLink;
    },
    quoteBeingUpdated: state => state.quoteBeingUpdated,
    appData: state => state.appData
  }
});
